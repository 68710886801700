import {
  Center,
  createStyles,
  Group,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { HeaderGroup } from "react-table";
import { SortAscending } from "tabler-icons-react";

interface TableHeaderProps {
  headerGroup: any;
  filter?: boolean;
}

const TableHeader: React.FC<TableHeaderProps> = (props: TableHeaderProps) => {
  const { headerGroup, filter = false } = props;
  const { classes } = useStyles();
  return (
    <tr {...headerGroup.getHeaderGroupProps()}>
      {
        // Loop over the headers in each row
        headerGroup.headers.map((column: any) => (
          // Apply the header cell props
          <th className={classes.th}>
            <UnstyledButton
              //onClick={onSort}
              className={classes.control}
            >
              <Group position="apart">
                <Text weight={500} size="sm">
                  {
                    // Render the header
                    column.render("Header")
                  }
                </Text>
                {filter && (
                  <Center className={classes.icon}>
                    <SortAscending size={14} />
                  </Center>
                )}
              </Group>
            </UnstyledButton>
          </th>
        ))
      }
    </tr>
  );
};

export default TableHeader;

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));
