import { AppShell } from "@mantine/core";
import { CalendarStats, Gauge, Network, Users } from "tabler-icons-react";
import React from "react";
import Sidebar from "../../components/layout/Sidebar";
import Header from "../../components/layout/Header";

const mockdata = [
  {
    label: "Dashboard",
    icon: Gauge,
    route: "/",
    permissions: ["admin", "reseller.manage"],
  },
  {
    label: "Distribuidores",
    icon: Network,
    route: "/resellers",
    permissions: ["admin"],
  },
  {
    label: "Empresas",
    icon: CalendarStats,
    route: "/clients",
    permissions: ["admin", "reseller.manage"],
  },
  {
    label: "Usuarios",
    icon: Users,
    route: "/users",
    permissions: ["admin", "reseller.manage"],
  },
  {
    label: "Releases",
    icon: CalendarStats,
    links: [
      { label: "Upcoming releases", route: "/a" },
      { label: "Previous releases", route: "/" },
      { label: "Releases schedule", route: "/" },
    ],
  },
];

export default function MainLayout(props: any) {
  const [opened, setOpened] = React.useState(false);
  return (
    <AppShell
      padding={"md"}
      navbarOffsetBreakpoint="sm"
      fixed
      navbar={<Sidebar hidden={opened} sidebarOptions={mockdata} />}
      header={<Header opened={opened} setOpened={setOpened} />}
    >
      {props.children}
    </AppShell>
  );
}
