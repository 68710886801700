import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Container,
  Group,
  Button,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useForm, yupResolver } from "@mantine/form";

import * as Yup from "yup";
import api from "../../../api";
import { saveToken } from "../../../utils/token";
import { useSWRConfig } from "swr";
import { showErrorToast } from "../../../utils/toasts";

const LoginPage = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      email: "",
      password: "",
    },
  });
  return (
    <div
      style={{
        minHeight: "98vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container size={"sm"} my={40} style={{ width: "100%" }}>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          Bienvenido
        </Title>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <form
            onSubmit={form.onSubmit((values) => {
              console.log(values);
              api
                .post("/login", values)
                .then((res) => {
                  if (res.data.token) {
                    saveToken(res.data.token);
                    mutate("/user", null, true);
                    navigate("/");
                  }
                })
                .catch((err) => {
                  if (err.response.status === 401) {
                    showErrorToast({
                      message: "Usuario o contraseña incorrectos",
                    });
                  }
                });
            })}
          >
            <TextInput
              label="Correo electronico"
              placeholder="admin@chiplevante.com"
              required
              {...form.getInputProps("email")}
            />
            <PasswordInput
              label="Contraseña"
              placeholder="Tu contraseña"
              required
              mt="md"
              {...form.getInputProps("password")}
            />
            <Group position="right" mt="md">
              {/*<Checkbox label="Remember me" />*/}
              <Anchor<"a">
                onClick={(event) => event.preventDefault()}
                href="#"
                size="sm"
              >
                Has olvidado tu contraseña?
              </Anchor>
            </Group>
            <Button type="submit" fullWidth mt="xl">
              Iniciar sesión
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default LoginPage;

const schema = Yup.object().shape({
  email: Yup.string()
    .email("El correo electronico no es valido")
    .required("Este campo es obligatorio"),
  password: Yup.string().required("Este campo es obligatorio"),
});
