import { Button, Select, Space, Text, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import api from "../../../../api";
import { handleResponseError } from "../../../../utils/errors";
import { showSuccessToast } from "../../../../utils/toasts";
import UserPermissions from "../../../core/UserPermissions";
import * as Yup from "yup";
import useSWR from "swr";

interface CreateUpdateUserFormProps {
  user?: any;
}

const CreateUpdateUserForm: React.FC<CreateUpdateUserFormProps> = (
  props: CreateUpdateUserFormProps
) => {
  const { user } = props;
  const navigate = useNavigate();

  const { data: authUser } = useSWR(`/user`, { suspense: true });

  const generateInitialValues = () => {
    if (user) {
      return {
        email: user.email,
        permissions: user.permissions,
        name: user.name,
        reseller_id: user.reseller_id,
      };
    }
    return {
      email: "",
      permissions: [],
      name: "",
      reseller_id: null,
    };
  };

  const form = useForm({
    initialValues: generateInitialValues(),
    schema: yupResolver(validationSchema),
  });

  const updatePermissions = (newPermissions: any) => {
    form.setFieldValue("permissions", newPermissions);
  };

  console.log(form.errors);

  const sendInvite = (values: any) => {
    api
      .post("/users", values)
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({ message: "Invitación enviada correctamente" });
          navigate("/users");
        }
      })
      .catch((error) => {
        handleResponseError(error);
      });
  };

  const updateUser = (values: any) => {
    api
      .put(`/users/${user.id}`, values)
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({ message: "Usuario actualizado correctamente" });
          navigate("/users");
        }
      })
      .catch((error) => {
        handleResponseError(error);
      });
  };

  const handleSubmit = (values: any) => {
    if (user) {
      updateUser(values);
    } else {
      sendInvite(values);
    }
  };
  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <TextInput
        label="Nombre y apellidos"
        name="name"
        required
        {...form.getInputProps("name")}
      />
      <TextInput
        label="Correo electrónico"
        pt="sm"
        required
        name="email"
        {...form.getInputProps("email")}
      />

      {authUser.permissions.some((r: string) =>
        ["admin", "superadmin"].includes(r)
      ) && <SelectReseller form={form} />}

      <Space mt="sm" />

      <Text weight={400} size="md" mb="sm">
        Permisos de usuario
      </Text>

      <UserPermissions
        permissions={form.values.permissions}
        updatePermissions={updatePermissions}
      />

      <Button type="submit" mt="md">
        {user ? "Actualizar " : "Invitar "}
        usuario
      </Button>
    </form>
  );
};

export default CreateUpdateUserForm;

const SelectReseller = ({ form }: any) => {
  const { data } = useSWR("/resellers", { suspense: true });

  const resellers = data && data.resellers;

  return (
    <Select
      pt={"sm"}
      label="Seleccionar reseller"
      name="reseller_id"
      searchable={true}
      value={String(form.values.reseller_id)}
      defaultValue={String(form.values.reseller_id)}
      data={resellers.map((reseller: any) => ({
        value: String(reseller.id),
        label: reseller.name,
      }))}
      {...form.getInputProps("reseller_id")}
    />
  );
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es obligatorio"),
  email: Yup.string()
    .email("El correo electrónico no es válido")
    .required("El correo electrónico es obligatorio"),
  permissions: Yup.array(),
  reseller_id: Yup.string().nullable(),
});
