import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import useUser from "./hooks/useUser";
import Login from "./pages/Auth/Login";
// import Companies from "./pages/Companies";
import Dashboard from "./pages/Dashboard";
import Resellers from "./pages/Resellers";
import CreateReseller from "./pages/Resellers/Create";
import UpdateReseller from "./pages/Resellers/UpdateReseller";
import UsersPage from "./pages/Users";
import NewUserInvite from "./pages/Users/NewInvite";
import UpdateUserPage from "./pages/Users/UpdateUser/UpdateUser";

const ProtectedRoute = ({
  user,
  redirectPath = "/auth/login",
  permissions = [],
}: any) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  if (permissions.length > 0) {
    permissions.push("superadmin");
    if (
      !user.permissions.some((permission: string) =>
        permissions.includes(permission)
      )
    ) {
      return <Navigate to={redirectPath} replace />;
    }
  }

  return <Outlet />;
};

const Router = () => {
  const { user, isLoading, isError } = useUser();

  if (!isLoading) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/auth/login" element={<Login />} />{" "}
          {/* Normal reseller and admin routes */}
          <Route element={<ProtectedRoute user={user} />}>
            <Route path="/" element={<Dashboard />} />
          </Route>
          {/* Admin routes */}
          <Route
            element={
              <ProtectedRoute
                user={user}
                permissions={["admin", "reseller.manage"]}
              />
            }
          >
            <Route path="/resellers" element={<Resellers />} />
            <Route path="/resellers/new" element={<CreateReseller />} />
            <Route path="/resellers/:id" element={<UpdateReseller />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/users/new-invite" element={<NewUserInvite />} />
            <Route path="/users/:id" element={<UpdateUserPage />} />
            {/* <Route path="/companies" element={<Companies />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return <></>;
};

export default Router;
