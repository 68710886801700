import { Badge, Button, Card, ScrollArea, Text } from "@mantine/core";
import Table from "../../../core/Table";
import dayjs from "dayjs";
import useSWR, { useSWRConfig } from "swr";
import api from "../../../../api";
import { showSuccessToast } from "../../../../utils/toasts";
import { handleResponseError } from "../../../../utils/errors";

const UsersInvitesList = () => {
  const { mutate } = useSWRConfig();
  const columns = [
    {
      Header: "Nombre",
      accessor: "name", // accessor is the "key" in the data
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Fecha",
      accessor: "sent_at",
      Cell: ({ cell: { value } }: any) => {
        return <Text>{dayjs(value).format("DD/MM/YYYY HH:mm:ss")}</Text>;
      },
    },
    {
      Header: "Estado",
      accessor: "expired",
      Cell: ({ cell: { value } }: any) => {
        if (value) {
          return (
            <Badge variant="gradient" gradient={{ from: "orange", to: "red" }}>
              Expirada
            </Badge>
          );
        } else {
          return (
            <Badge variant="gradient" gradient={{ from: "indigo", to: "cyan" }}>
              Pendiente
            </Badge>
          );
        }
      },
    },
    {
      Header: " ",
      Cell: ({ cell: { row } }: any) => {
        if (row.original.expired) {
          const sendResendInvite = () => {
            api
              .post(`/invites/${row.original.id}/resend`)
              .then((res) => {
                if (res.data.ok) {
                  showSuccessToast({
                    message: "Invitación reenviada correctamente",
                  });
                  mutate("/invites");
                }
              })
              .catch((err) => {
                handleResponseError(err);
              });
          };
          return (
            <Button
              color="primary"
              size="sm"
              compact
              onClick={sendResendInvite}
            >
              Reenviar
            </Button>
          );
        }
      },
    },
  ];

  const {
    data: { invites },
  } = useSWR("/invites", { suspense: true });
  return (
    <Card mt="md">
      <Card.Section p={"md"}>
        <Text weight={500} size="lg">
          Invitaciones pendientes
        </Text>
      </Card.Section>
      <ScrollArea>
        <Table data={invites} columns={columns} />
      </ScrollArea>
    </Card>
  );
};

export default UsersInvitesList;
