import { Card, Text } from "@mantine/core";
import MainLayout from "../../../layout/MainLayout";
import CreateUpdateUserForm from "../../../components/pages/Users/CreateUpdateUserForm";
import SWRrender from "../../../components/core/SWRrender";

const NewUserInvite = () => {
  return (
    <MainLayout>
      <Card>
        <Card.Section p={"md"}>
          <Text weight={500} size="lg">
            Invitar nuevo usuario
          </Text>
        </Card.Section>
        <SWRrender>
          <CreateUpdateUserForm />
        </SWRrender>
      </Card>
    </MainLayout>
  );
};

export default NewUserInvite;
