import MainLayout from "../../../layout/MainLayout";
import { useParams } from "react-router-dom";
import SWRrender from "../../../components/core/SWRrender";
import useSWRImmutable from "swr/immutable";
import CreateUpdateUserForm from "../../../components/pages/Users/CreateUpdateUserForm";
import { Card } from "@mantine/core";

const UpdateUserPage = () => {
  const { id } = useParams();

  return (
    <MainLayout>
      <SWRrender
        redirectOnError="/users"
        toastMessage="No se ha podido encontrar el usuario"
      >
        <UpdateForm id={id} />
      </SWRrender>
    </MainLayout>
  );
};

const UpdateForm = ({ id }: any) => {
  const { data } = useSWRImmutable(`/users/${id}`, { suspense: true });
  return (
    <Card>
      <SWRrender>
        <CreateUpdateUserForm user={data.user} />
      </SWRrender>
    </Card>
  );
};

export default UpdateUserPage;
