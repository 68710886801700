import { ScrollArea, Text, Card } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { Plus } from "tabler-icons-react";
import Table from "../../../core/Table";
import TableHeaderSearch from "../../../core/TableHeaderSearch";

const ResellersList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = React.useState<any>("");

  const {
    data: { resellers },
  } = useSWR("/resellers", { suspense: true });

  const columns = React.useMemo(
    () => [
      {
        Header: "Nombre",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Administradores",
        accessor: "managers",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Text>
              {value.map((manager: any, index: number) => (
                <>
                  {manager.name}
                  {value.length - 1 != index && ", "}
                </>
              ))}
            </Text>
          );
        },
      },
    ],
    []
  );

  return (
    <Card>
      <Card.Section p={"md"}>
        <Text weight={500} size="lg">
          Listado de distribuidores
        </Text>
      </Card.Section>

      <TableHeaderSearch
        buttonIcon={<Plus />}
        buttonText="Crear distribuidor"
        placeholder="Buscar distribudor"
        onSearch={(e) => setSearch(e.target.value)}
        onAdd={() => navigate("/resellers/new")}
      />

      <ScrollArea>
        <Table
          data={resellers}
          columns={columns}
          enableSearch={true}
          search={search}
          onRowClick="/resellers/"
        />
      </ScrollArea>
    </Card>
  );
};

export default ResellersList;
