import React from "react";
import MainLayout from "../../layout/MainLayout";
import ResellersList from "../../components/pages/Resellers/ResellersList";
import SWRrender from "../../components/core/SWRrender";

const ResellersPage = () => {
  return (
    <MainLayout>
      <SWRrender>
        <ResellersList />
      </SWRrender>
    </MainLayout>
  );
};

export default ResellersPage;
