import {
  Box,
  Burger,
  Header,
  Image,
  MediaQuery,
  useMantineTheme,
} from "@mantine/core";
import logo from "../../../assets/logo.png";

const LayoutHeader = (props: any) => {
  const { opened, setOpened } = props;
  const theme = useMantineTheme();
  return (
    <Header height={60} p="md">
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Burger
            opened={opened}
            onClick={() => setOpened((o: any) => !o)}
            size="sm"
            color={theme.colors.gray[6]}
            mr="xl"
          />
        </MediaQuery>

        <Box
          style={{
            width: "300px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image src={logo} width={120} />
        </Box>
      </div>
    </Header>
  );
};

export default LayoutHeader;
