import { createStyles, Navbar, ScrollArea } from "@mantine/core";
import UserButton from "../UserButton";
import LinksGroup from "../NavbarLinksGroup/NavbarLinksGroup";
import useUser from "../../../hooks/useUser";

const Sidebar = (props: any) => {
  const { sidebarOptions = [] } = props;
  const { classes } = useStyles();
  const { user } = useUser();
  const links = sidebarOptions.map((item: any) => {
    if (item.permissions?.length > 0) {
      item.permissions.push("superadmin");
      if (
        !item.permissions.some((permission: string) =>
          user.permissions.includes(permission)
        )
      ) {
        return;
      }
    }
    return <LinksGroup {...item} key={item.label} />;
  });

  return (
    <Navbar
      hiddenBreakpoint={"sm"}
      width={{ sm: 300 }}
      p="md"
      hidden={!props.hidden}
      className={classes.navbar}
    >
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <UserButton
          image="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80"
          name="Alberto Esquitino"
          email="alberto@chiplevante.com"
        />
      </Navbar.Section>
    </Navbar>
  );
};

export default Sidebar;

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));
