import { Box, Button, Input, MediaQuery, Text } from "@mantine/core";
import { ChangeEvent } from "react";

interface TableHeaderSearchProps {
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  onAdd?: () => void;
  buttonText?: string;
  placeholder?: string;
  buttonIcon?: JSX.Element;
}

const TableHeaderSearch: React.FC<TableHeaderSearchProps> = (
  props: TableHeaderSearchProps
) => {
  const { onSearch, buttonIcon, onAdd, placeholder, buttonText } = props;

  const showButton = !buttonText && !buttonIcon ? false : true;
  return (
    <Box style={{ display: "flex", width: "100%" }}>
      {showButton && (
        <Button
          onClick={onAdd}
          style={{ display: "flex", alignItems: "center" }}
        >
          {buttonIcon}
          <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
            <div>{buttonText}</div>
          </MediaQuery>
        </Button>
      )}
      <Input
        onChange={onSearch}
        pl={showButton ? "sm" : "0"}
        style={{ width: "100%" }}
        placeholder={placeholder}
      />
    </Box>
  );
};

export default TableHeaderSearch;
