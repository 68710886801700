import { Card, ScrollArea, Text } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { Plus } from "tabler-icons-react";
import Table from "../../../core/Table";
import TableHeaderSearch from "../../../core/TableHeaderSearch";

const UsersList = () => {
  const columns = [
    {
      Header: "Nombre",
      accessor: "name", // accessor is the "key" in the data
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Distribuidor",
      accessor: "reseller.name",
    },
    {
      Header: "Activo",
      accessor: "permissions",
      Cell: ({ cell: { value } }) => {
        return <Text>{value.length > 0 ? "Si" : "No"}</Text>;
      },
    },
  ];

  const { data } = useSWR("/users", { suspense: true });
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const onAddUser = () => {
    navigate("/users/new-invite");
  };

  return (
    <Card>
      <Card.Section p={"md"}>
        <Text weight={500} size="lg">
          Listado de usuarios
        </Text>
      </Card.Section>
      <TableHeaderSearch
        buttonIcon={<Plus />}
        buttonText="Invitar usuario"
        placeholder="Buscar usuario"
        onSearch={(e) => setSearch(e.target.value)}
        onAdd={onAddUser}
      />
      <ScrollArea>
        <Table
          data={data.users}
          columns={columns}
          enableSearch={true}
          search={search}
          onRowClick={"/users/"}
        />
      </ScrollArea>
    </Card>
  );
};

export default UsersList;
