import { Button, Group, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import api from "../../../../api";
import { handleResponseError } from "../../../../utils/errors";
import { showSuccessToast } from "../../../../utils/toasts";

interface CreateResellerFormProps {
  reseller?: any;
}

const CreateUpdateResellerForm: React.FC<CreateResellerFormProps> = (
  props: CreateResellerFormProps
) => {
  const navigate = useNavigate();
  const { reseller } = props;

  const generateInitialValues = () => {
    if (reseller) {
      return {
        name: reseller.name,
      };
    }
    return {
      name: "",
    };
  };

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: generateInitialValues(),
  });

  const create = (values: any) => {
    api
      .post("/resellers", values)
      .then((res: any) => {
        if (res.data.ok) {
          showSuccessToast({ message: "Distribuidor creado correctamente" });
          navigate("/resellers");
        }
      })
      .catch((error) => {
        handleResponseError(error);
      });
  };

  const update = (values: any) => {
    api
      .put(`/resellers/${reseller.id}`, values)
      .then((res: any) => {
        if (res.data.ok) {
          showSuccessToast({
            message: "Distribuidor actualizado correctamente",
          });
          navigate("/resellers");
        }
      })
      .catch((error) => {
        handleResponseError(error);
      });
  };

  const handleSubmit = (values: any) => {
    if (reseller) {
      update(values);
    } else {
      create(values);
    }
  };

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <TextInput
        label="Nombre del distribuidor"
        name="name"
        required
        {...form.getInputProps("name")}
      />
      <Group mt="md" position="right">
        <Button type="submit">
          {reseller ? "Actualizar distribuidor" : "Crear distribuidor"}
        </Button>
      </Group>
    </form>
  );
};

const schema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
});

export default CreateUpdateResellerForm;
