import { Group, Pagination, Table as MantineTable } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePagination, useTable } from "react-table";
import TableHeader from "../TableHeader";

const Table = ({
  columns,
  data,
  enableSearch = false,
  search = null,
  onRowClick = "",
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  const tableInstance = useTable(
    { columns, data: filteredData, initialState: { pageIndex: 0 } },
    usePagination
  );

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const handlePageChange = (e) => {
    gotoPage(e - 1);
  };

  useEffect(() => {
    if (search) {
      const filteredData = data.filter((row) => {
        return Object.values(row).some((cell) => {
          return String(cell).toLowerCase().includes(search.toLowerCase());
        });
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData(data);
    }
  }, [search, data]);

  return (
    <>
      <MantineTable>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <TableHeader headerGroup={headerGroup} />
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() =>
                  onRowClick && navigate(onRowClick + row.original.id)
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </MantineTable>

      <Group position="right" mt="sm">
        <Pagination
          page={pageIndex + 1}
          onChange={handlePageChange}
          total={pageCount}
        />
      </Group>
    </>
  );
};

export default Table;
