import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider } from "@mantine/core";
import { SWRConfig } from "swr";
import "./index.css";
import { getAccessToken } from "./utils/token";
import { NotificationsProvider } from "@mantine/notifications";

const root = ReactDOM.createRoot(document.getElementById("root"));

const fetcher = async (url, params) => {
  const res = await fetch((process.env.REACT_APP_API_ENDPOINT || "https://api-canary.chiplevante.es/api/admin") + url, {
    ...params,
    headers: {
      Authorization: "Bearer " + getAccessToken(),
      Accept: "application/json",
    },
  });
  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};
root.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        fetcher: fetcher,
      }}
    >
      <MantineProvider theme={{ colorScheme: "dark" }} withGlobalStyles>
        <NotificationsProvider position="top-right" zIndex={2077} limit={1}>
          <App />
        </NotificationsProvider>
      </MantineProvider>
    </SWRConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
