import { showErrorToast } from "./toasts";

export const handleResponseError = (error: any) => {
  const errors = error.response.data.errors;

  if (error.response.status === 422) {
    const errors_array = Object.values(errors);

    errors_array.forEach((error) => {
      showErrorToast({ message: error });
    });
  }

  if (error.response.status === 500) {
    showErrorToast({ message: "Se ha producido un error (500)" });
  }
};
