import { useParams } from "react-router-dom";
import CreateUpdateResellerForm from "../../../components/pages/Resellers/CreateUpdateResellerForm";
import MainLayout from "../../../layout/MainLayout";
import useSWRImmutable from "swr/immutable";
import SWRrender from "../../../components/core/SWRrender";
import { Card, Text } from "@mantine/core";

const UpdateReseller = () => {
  const { id } = useParams();
  return (
    <MainLayout>
      <SWRrender>
        <UpdateForm id={id} />
      </SWRrender>
    </MainLayout>
  );
};

export default UpdateReseller;

const UpdateForm = ({ id }: any) => {
  const {
    data: { reseller },
  } = useSWRImmutable(`/resellers/${id}`, {
    suspense: true,
  });

  //TODO: FORCE REVALIDATE WHEN LOAD RESELLER
  return (
    <Card>
      <Card.Section p={"md"}>
        <Text weight={500} size="lg">
          Actualizar distribuidor
        </Text>
      </Card.Section>
      <CreateUpdateResellerForm reseller={reseller} />
    </Card>
  );
};
