import { Button, Card, Group, Input, Text, TextInput } from "@mantine/core";
import { yupResolver, useForm } from "@mantine/form";
import MainLayout from "../../../layout/MainLayout";
import * as Yup from "yup";
import CreateUpdateResellerForm from "../../../components/pages/Resellers/CreateUpdateResellerForm";

const CreateResellersPage = () => {
  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      name: "",
    },
  });

  return (
    <MainLayout>
      <Card p={"md"}>
        <Card.Section p={"md"}>
          <Text weight={500} size="lg">
            Nuevo distribuidor
          </Text>
        </Card.Section>

        <CreateUpdateResellerForm />
      </Card>
    </MainLayout>
  );
};

const schema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
});

export default CreateResellersPage;
