import {
  Accordion,
  Checkbox,
  Group,
  Text,
  useAccordionState,
} from "@mantine/core";
import useSWR from "swr";

interface UserPermissionsAccordionLabelProps {
  name: string;
  description: string;
  node: string;
  permissions: string[];
  setPermissions: (newPermissions: string[]) => void;
}

const UserPermissionsAccordionLabel: React.FC<
  UserPermissionsAccordionLabelProps
> = (props: UserPermissionsAccordionLabelProps) => {
  const { name, node, description, setPermissions, permissions } = props;

  const handleGlobalPermissionsGroupCheckboxChange = (e: any) => {
    // Check if the node permissions is checked
    const { checked, value } = e.target;
    if (checked) {
      if (
        permissions.filter((permission: any) =>
          permission.startsWith(value + ".")
        ).length === 0
      ) {
        // If the node permissions is not checked, and no node permissions
        setPermissions([...permissions, value + ".*"]);
      } else {
        // Remove node permissions and add global node permission
        const tempPermissions = permissions.filter(
          (permission: any) => !permission.startsWith(value + ".")
        );
        tempPermissions.push(value + ".*");
        setPermissions(tempPermissions);
      }
    } else {
      // Remove this global permission
      setPermissions(permissions.filter((p: any) => p !== value + ".*"));
    }
  };

  const checkParentCheckBoxStatus = (newPermissions: any) => {
    if (permissions.includes(newPermissions + ".*")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Group noWrap>
      <Checkbox
        value={node}
        checked={checkParentCheckBoxStatus(node)}
        onChange={handleGlobalPermissionsGroupCheckboxChange}
      ></Checkbox>
      <div>
        <Text>{name}</Text>
        <Text size="sm" color="dimmed" weight={400}>
          {description}
        </Text>
      </div>
    </Group>
  );
};

interface UserPermissionsProps {
  permissions?: string[] | null;
  updatePermissions: (permissions: string[]) => void;
}

const UserPermissions: React.FC<UserPermissionsProps> = (
  props: UserPermissionsProps
) => {
  const {
    permissions: userPermissions,
    updatePermissions: updateUserPermissions,
  } = props;

  const { data, error } = useSWR("/permissions/list", { suspense: true });

  const [state, handlers] = useAccordionState({ total: 3, initialItem: 0 });

  if (!data && !error) {
    return <></>;
  }

  const handleCheckboxChange = (permission: string) => {
    let newUserPermissions: any = userPermissions && [...userPermissions];

    if (newUserPermissions.includes(permission)) {
      newUserPermissions.splice(newUserPermissions.indexOf(permission), 1);
    } else {
      newUserPermissions.push(permission);
    }

    updateUserPermissions(newUserPermissions);
  };

  const permissions = data && data.list;

  const items = permissions.map((permission: any) => {
    const parentChecked =
      userPermissions && userPermissions.includes(permission.node + ".*");

    const checkCheckBoxStatus = (newPermissions: any) => {
      if (parentChecked) {
        return true;
      } else {
        if (userPermissions && userPermissions.includes(newPermissions)) {
          return true;
        } else {
          return false;
        }
      }
    };
    return (
      <Accordion.Item
        label={
          <UserPermissionsAccordionLabel
            {...permission}
            setPermissions={updateUserPermissions}
            permissions={userPermissions}
          />
        }
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "33% 33% 33%",
            gridGap: "1rem",
          }}
        >
          {permission.permissions.map((item: any) => (
            <Checkbox
              label={item.name}
              value={item.value}
              onChange={(e) => handleCheckboxChange(e.target.value)}
              disabled={Boolean(parentChecked)}
              checked={checkCheckBoxStatus(item.value)}
            ></Checkbox>
          ))}
        </div>
      </Accordion.Item>
    );
  });

  return (
    <>
      <Accordion
        state={state}
        onChange={handlers.setState}
        disableIconRotation
        initialItem={0}
        iconPosition="right"
      >
        {items}
      </Accordion>
    </>
  );
};

export default UserPermissions;
