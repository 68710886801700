import SWRrender from "../../components/core/SWRrender";
import UsersInvitesList from "../../components/pages/Users/UsersInvitesList";
import UsersList from "../../components/pages/Users/UsersList";
import MainLayout from "../../layout/MainLayout";

const UsersPage = () => {
  return (
    <MainLayout>
      <SWRrender loadingHeight={300}>
        <UsersList />
      </SWRrender>

      <SWRrender loadingHeight={300}>
        <UsersInvitesList />
      </SWRrender>
    </MainLayout>
  );
};

export default UsersPage;
